<template>
  <section id="politica-De-Privacidad">
    <PoliticaDePrivacidad />
    <Footer />
  </section>
</template>

<script>
// import PoliticaDePrivacidad from "@/components/politicaDePrivacidad/PoliticaDePrivacidadPrincipal.vue";
import { defineAsyncComponent } from "vue";

export default {
  name: "Politica_De_Privacidad",
  components: {
    PoliticaDePrivacidad: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "PoliticaDePrivacidad" */ "@/components/politicaDePrivacidad/PoliticaDePrivacidadPrincipal.vue"
      )
    ),
    Footer: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Footer-politica" */ "@/components/footer/Footer.vue")
    ),
  },
};
</script>